export const priceList = {
  "121PLN": {
    "price_list_id": 1,
    "variant_id": 86,
    "price": 100.54,
    "sale_price": null,
    "retail_price": null,
    "map_price": null,
    "calculated_price": 100.54,
    "date_created": "2021-04-29T09:47:15Z",
    "date_modified": "2021-05-21T13:03:09Z",
    "currency": "pln",
    "product_id": 121
  },
  "121AED": {
    "price_list_id": 1,
    "variant_id": 86,
    "price": 605.44,
    "sale_price": null,
    "retail_price": null,
    "map_price": null,
    "calculated_price": 605.44,
    "date_created": "2021-04-29T09:47:15Z",
    "date_modified": "2021-05-21T13:03:09Z",
    "currency": "aed",
    "product_id": 121
  },
  "121JPY": {
    "price_list_id": 1,
    "variant_id": 86,
    "price": 100,
    "sale_price": null,
    "retail_price": null,
    "map_price": null,
    "calculated_price": 100,
    "date_created": "2021-04-29T09:47:15Z",
    "date_modified": "2021-04-29T09:47:15Z",
    "currency": "jpy",
    "product_id": 121
  },
  "121CHF": {
    "price_list_id": 1,
    "variant_id": 86,
    "price": 160,
    "sale_price": null,
    "retail_price": null,
    "map_price": null,
    "calculated_price": 160,
    "date_created": "2021-04-29T09:47:15Z",
    "date_modified": "2021-05-17T21:48:23Z",
    "currency": "chf",
    "product_id": 121
  },
  "121HKD": {
    "price_list_id": 1,
    "variant_id": 86,
    "price": 1100,
    "sale_price": null,
    "retail_price": null,
    "map_price": null,
    "calculated_price": 1100,
    "date_created": "2021-04-29T09:47:14Z",
    "date_modified": "2021-05-17T21:48:23Z",
    "currency": "hkd",
    "product_id": 121
  },
  "121SEK": {
    "price_list_id": 1,
    "variant_id": 86,
    "price": 100,
    "sale_price": null,
    "retail_price": null,
    "map_price": null,
    "calculated_price": 100,
    "date_created": "2021-04-29T09:47:15Z",
    "date_modified": "2021-04-29T09:47:15Z",
    "currency": "sek",
    "product_id": 121
  },
  "121DKK": {
    "price_list_id": 1,
    "variant_id": 86,
    "price": 100,
    "sale_price": null,
    "retail_price": null,
    "map_price": null,
    "calculated_price": 100,
    "date_created": "2021-04-29T09:47:14Z",
    "date_modified": "2021-04-29T09:47:14Z",
    "currency": "dkk",
    "product_id": 121
  },
  "121CZK": {
    "price_list_id": 1,
    "variant_id": 86,
    "price": 100,
    "sale_price": null,
    "retail_price": null,
    "map_price": null,
    "calculated_price": 100,
    "date_created": "2021-04-29T09:47:14Z",
    "date_modified": "2021-04-29T09:47:14Z",
    "currency": "czk",
    "product_id": 121
  },
  "121KRW": {
    "price_list_id": 1,
    "variant_id": 86,
    "price": 100,
    "sale_price": null,
    "retail_price": null,
    "map_price": null,
    "calculated_price": 100,
    "date_created": "2021-04-29T09:47:15Z",
    "date_modified": "2021-04-29T09:47:15Z",
    "currency": "krw",
    "product_id": 121
  },
  "121GBP": {
    "price_list_id": 1,
    "variant_id": 86,
    "price": 105,
    "sale_price": null,
    "retail_price": null,
    "map_price": null,
    "calculated_price": 105,
    "date_created": "2021-04-29T09:47:14Z",
    "date_modified": "2021-06-17T10:57:16Z",
    "currency": "gbp",
    "product_id": 121
  },
  "121RUB": {
    "price_list_id": 1,
    "variant_id": 86,
    "price": 100,
    "sale_price": null,
    "retail_price": null,
    "map_price": null,
    "calculated_price": 100,
    "date_created": "2021-04-29T09:47:15Z",
    "date_modified": "2021-04-29T09:47:15Z",
    "currency": "rub",
    "product_id": 121
  },
  "121AUD": {
    "price_list_id": 1,
    "variant_id": 86,
    "price": 100,
    "sale_price": null,
    "retail_price": null,
    "map_price": null,
    "calculated_price": 100,
    "date_created": "2021-04-29T09:47:14Z",
    "date_modified": "2021-04-29T09:47:14Z",
    "currency": "aud",
    "product_id": 121
  },
  "121EUR": {
    "price_list_id": 1,
    "variant_id": 86,
    "price": 120,
    "sale_price": null,
    "retail_price": null,
    "map_price": null,
    "calculated_price": 120,
    "date_created": "2021-04-29T09:47:14Z",
    "date_modified": "2022-06-29T11:26:51Z",
    "currency": "eur",
    "product_id": 121
  },
  "122RUB": {
    "price_list_id": 1,
    "variant_id": 87,
    "price": 100,
    "sale_price": null,
    "retail_price": null,
    "map_price": null,
    "calculated_price": 100,
    "date_created": "2021-04-29T09:47:15Z",
    "date_modified": "2021-04-29T09:47:15Z",
    "currency": "rub",
    "product_id": 122
  },
  "122AED": {
    "price_list_id": 1,
    "variant_id": 87,
    "price": 185,
    "sale_price": null,
    "retail_price": null,
    "map_price": null,
    "calculated_price": 185,
    "date_created": "2021-04-29T09:47:15Z",
    "date_modified": "2021-05-24T19:12:31Z",
    "currency": "aed",
    "product_id": 122
  },
  "122CHF": {
    "price_list_id": 1,
    "variant_id": 87,
    "price": 48,
    "sale_price": null,
    "retail_price": null,
    "map_price": null,
    "calculated_price": 48,
    "date_created": "2021-04-29T09:47:15Z",
    "date_modified": "2021-05-24T19:12:31Z",
    "currency": "chf",
    "product_id": 122
  },
  "122EUR": {
    "price_list_id": 1,
    "variant_id": 87,
    "price": 37,
    "sale_price": null,
    "retail_price": null,
    "map_price": null,
    "calculated_price": 37,
    "date_created": "2021-04-29T09:47:14Z",
    "date_modified": "2022-06-29T11:26:51Z",
    "currency": "eur",
    "product_id": 122
  },
  "122JPY": {
    "price_list_id": 1,
    "variant_id": 87,
    "price": 100,
    "sale_price": null,
    "retail_price": null,
    "map_price": null,
    "calculated_price": 100,
    "date_created": "2021-04-29T09:47:15Z",
    "date_modified": "2021-04-29T09:47:15Z",
    "currency": "jpy",
    "product_id": 122
  },
  "122PLN": {
    "price_list_id": 1,
    "variant_id": 87,
    "price": 100,
    "sale_price": null,
    "retail_price": null,
    "map_price": null,
    "calculated_price": 100,
    "date_created": "2021-04-29T09:47:15Z",
    "date_modified": "2021-04-29T09:47:15Z",
    "currency": "pln",
    "product_id": 122
  },
  "122HKD": {
    "price_list_id": 1,
    "variant_id": 87,
    "price": 350,
    "sale_price": null,
    "retail_price": null,
    "map_price": null,
    "calculated_price": 350,
    "date_created": "2021-04-29T09:47:14Z",
    "date_modified": "2021-05-24T19:12:31Z",
    "currency": "hkd",
    "product_id": 122
  },
  "122SEK": {
    "price_list_id": 1,
    "variant_id": 87,
    "price": 100,
    "sale_price": null,
    "retail_price": null,
    "map_price": null,
    "calculated_price": 100,
    "date_created": "2021-04-29T09:47:15Z",
    "date_modified": "2021-04-29T09:47:15Z",
    "currency": "sek",
    "product_id": 122
  },
  "122AUD": {
    "price_list_id": 1,
    "variant_id": 87,
    "price": 100,
    "sale_price": null,
    "retail_price": null,
    "map_price": null,
    "calculated_price": 100,
    "date_created": "2021-04-29T09:47:14Z",
    "date_modified": "2021-04-29T09:47:14Z",
    "currency": "aud",
    "product_id": 122
  },
  "122DKK": {
    "price_list_id": 1,
    "variant_id": 87,
    "price": 100,
    "sale_price": null,
    "retail_price": null,
    "map_price": null,
    "calculated_price": 100,
    "date_created": "2021-04-29T09:47:14Z",
    "date_modified": "2021-04-29T09:47:14Z",
    "currency": "dkk",
    "product_id": 122
  },
  "122KRW": {
    "price_list_id": 1,
    "variant_id": 87,
    "price": 100,
    "sale_price": null,
    "retail_price": null,
    "map_price": null,
    "calculated_price": 100,
    "date_created": "2021-04-29T09:47:15Z",
    "date_modified": "2021-04-29T09:47:15Z",
    "currency": "krw",
    "product_id": 122
  },
  "122CZK": {
    "price_list_id": 1,
    "variant_id": 87,
    "price": 100,
    "sale_price": null,
    "retail_price": null,
    "map_price": null,
    "calculated_price": 100,
    "date_created": "2021-04-29T09:47:14Z",
    "date_modified": "2021-04-29T09:47:14Z",
    "currency": "czk",
    "product_id": 122
  },
  "122GBP": {
    "price_list_id": 1,
    "variant_id": 87,
    "price": 32,
    "sale_price": null,
    "retail_price": null,
    "map_price": null,
    "calculated_price": 32,
    "date_created": "2021-04-29T09:47:14Z",
    "date_modified": "2021-06-17T10:57:16Z",
    "currency": "gbp",
    "product_id": 122
  },
  "123PLN": {
    "price_list_id": 1,
    "variant_id": 88,
    "price": 100,
    "sale_price": null,
    "retail_price": null,
    "map_price": null,
    "calculated_price": 100,
    "date_created": "2021-04-29T09:47:15Z",
    "date_modified": "2021-04-29T09:47:15Z",
    "currency": "pln",
    "product_id": 123
  },
  "123SEK": {
    "price_list_id": 1,
    "variant_id": 88,
    "price": 100,
    "sale_price": null,
    "retail_price": null,
    "map_price": null,
    "calculated_price": 100,
    "date_created": "2021-04-29T09:47:15Z",
    "date_modified": "2021-04-29T09:47:15Z",
    "currency": "sek",
    "product_id": 123
  },
  "123KRW": {
    "price_list_id": 1,
    "variant_id": 88,
    "price": 100,
    "sale_price": null,
    "retail_price": null,
    "map_price": null,
    "calculated_price": 100,
    "date_created": "2021-04-29T09:47:15Z",
    "date_modified": "2021-04-29T09:47:15Z",
    "currency": "krw",
    "product_id": 123
  },
  "123RUB": {
    "price_list_id": 1,
    "variant_id": 88,
    "price": 100,
    "sale_price": null,
    "retail_price": null,
    "map_price": null,
    "calculated_price": 100,
    "date_created": "2021-04-29T09:47:15Z",
    "date_modified": "2021-04-29T09:47:15Z",
    "currency": "rub",
    "product_id": 123
  },
  "123CHF": {
    "price_list_id": 1,
    "variant_id": 88,
    "price": 48,
    "sale_price": null,
    "retail_price": null,
    "map_price": null,
    "calculated_price": 48,
    "date_created": "2021-04-29T09:47:15Z",
    "date_modified": "2021-05-24T19:12:31Z",
    "currency": "chf",
    "product_id": 123
  },
  "123JPY": {
    "price_list_id": 1,
    "variant_id": 88,
    "price": 100,
    "sale_price": null,
    "retail_price": null,
    "map_price": null,
    "calculated_price": 100,
    "date_created": "2021-04-29T09:47:15Z",
    "date_modified": "2021-04-29T09:47:15Z",
    "currency": "jpy",
    "product_id": 123
  },
  "123AED": {
    "price_list_id": 1,
    "variant_id": 88,
    "price": 185,
    "sale_price": null,
    "retail_price": null,
    "map_price": null,
    "calculated_price": 185,
    "date_created": "2021-04-29T09:47:15Z",
    "date_modified": "2021-05-24T19:12:31Z",
    "currency": "aed",
    "product_id": 123
  },
  "123EUR": {
    "price_list_id": 1,
    "variant_id": 88,
    "price": 37,
    "sale_price": null,
    "retail_price": null,
    "map_price": null,
    "calculated_price": 37,
    "date_created": "2021-04-29T09:47:14Z",
    "date_modified": "2022-06-29T11:26:51Z",
    "currency": "eur",
    "product_id": 123
  },
  "123AUD": {
    "price_list_id": 1,
    "variant_id": 88,
    "price": 100,
    "sale_price": null,
    "retail_price": null,
    "map_price": null,
    "calculated_price": 100,
    "date_created": "2021-04-29T09:47:14Z",
    "date_modified": "2021-04-29T09:47:14Z",
    "currency": "aud",
    "product_id": 123
  },
  "123GBP": {
    "price_list_id": 1,
    "variant_id": 88,
    "price": 32,
    "sale_price": null,
    "retail_price": null,
    "map_price": null,
    "calculated_price": 32,
    "date_created": "2021-04-29T09:47:14Z",
    "date_modified": "2021-06-17T10:57:16Z",
    "currency": "gbp",
    "product_id": 123
  },
  "123CZK": {
    "price_list_id": 1,
    "variant_id": 88,
    "price": 100,
    "sale_price": null,
    "retail_price": null,
    "map_price": null,
    "calculated_price": 100,
    "date_created": "2021-04-29T09:47:14Z",
    "date_modified": "2021-04-29T09:47:14Z",
    "currency": "czk",
    "product_id": 123
  },
  "123DKK": {
    "price_list_id": 1,
    "variant_id": 88,
    "price": 100,
    "sale_price": null,
    "retail_price": null,
    "map_price": null,
    "calculated_price": 100,
    "date_created": "2021-04-29T09:47:14Z",
    "date_modified": "2021-04-29T09:47:14Z",
    "currency": "dkk",
    "product_id": 123
  },
  "123HKD": {
    "price_list_id": 1,
    "variant_id": 88,
    "price": 350,
    "sale_price": null,
    "retail_price": null,
    "map_price": null,
    "calculated_price": 350,
    "date_created": "2021-04-29T09:47:14Z",
    "date_modified": "2021-05-24T19:12:31Z",
    "currency": "hkd",
    "product_id": 123
  },
  "124HKD": {
    "price_list_id": 1,
    "variant_id": 89,
    "price": 350,
    "sale_price": null,
    "retail_price": null,
    "map_price": null,
    "calculated_price": 350,
    "date_created": "2021-04-29T09:47:14Z",
    "date_modified": "2021-05-24T19:12:32Z",
    "currency": "hkd",
    "product_id": 124
  },
  "124KRW": {
    "price_list_id": 1,
    "variant_id": 89,
    "price": 100,
    "sale_price": null,
    "retail_price": null,
    "map_price": null,
    "calculated_price": 100,
    "date_created": "2021-04-29T09:47:15Z",
    "date_modified": "2021-04-29T09:47:15Z",
    "currency": "krw",
    "product_id": 124
  },
  "124DKK": {
    "price_list_id": 1,
    "variant_id": 89,
    "price": 100,
    "sale_price": null,
    "retail_price": null,
    "map_price": null,
    "calculated_price": 100,
    "date_created": "2021-04-29T09:47:14Z",
    "date_modified": "2021-04-29T09:47:14Z",
    "currency": "dkk",
    "product_id": 124
  },
  "124JPY": {
    "price_list_id": 1,
    "variant_id": 89,
    "price": 100,
    "sale_price": null,
    "retail_price": null,
    "map_price": null,
    "calculated_price": 100,
    "date_created": "2021-04-29T09:47:15Z",
    "date_modified": "2021-04-29T09:47:15Z",
    "currency": "jpy",
    "product_id": 124
  },
  "124CZK": {
    "price_list_id": 1,
    "variant_id": 89,
    "price": 100,
    "sale_price": null,
    "retail_price": null,
    "map_price": null,
    "calculated_price": 100,
    "date_created": "2021-04-29T09:47:14Z",
    "date_modified": "2021-04-29T09:47:14Z",
    "currency": "czk",
    "product_id": 124
  },
  "124EUR": {
    "price_list_id": 1,
    "variant_id": 89,
    "price": 37,
    "sale_price": null,
    "retail_price": null,
    "map_price": null,
    "calculated_price": 37,
    "date_created": "2021-04-29T09:47:14Z",
    "date_modified": "2022-06-29T11:26:51Z",
    "currency": "eur",
    "product_id": 124
  },
  "124GBP": {
    "price_list_id": 1,
    "variant_id": 89,
    "price": 32,
    "sale_price": null,
    "retail_price": null,
    "map_price": null,
    "calculated_price": 32,
    "date_created": "2021-04-29T09:47:14Z",
    "date_modified": "2021-06-17T10:57:16Z",
    "currency": "gbp",
    "product_id": 124
  },
  "124PLN": {
    "price_list_id": 1,
    "variant_id": 89,
    "price": 100,
    "sale_price": null,
    "retail_price": null,
    "map_price": null,
    "calculated_price": 100,
    "date_created": "2021-04-29T09:47:15Z",
    "date_modified": "2021-04-29T09:47:15Z",
    "currency": "pln",
    "product_id": 124
  },
  "124AED": {
    "price_list_id": 1,
    "variant_id": 89,
    "price": 185,
    "sale_price": null,
    "retail_price": null,
    "map_price": null,
    "calculated_price": 185,
    "date_created": "2021-04-29T09:47:15Z",
    "date_modified": "2021-05-24T19:12:32Z",
    "currency": "aed",
    "product_id": 124
  },
  "124AUD": {
    "price_list_id": 1,
    "variant_id": 89,
    "price": 100,
    "sale_price": null,
    "retail_price": null,
    "map_price": null,
    "calculated_price": 100,
    "date_created": "2021-04-29T09:47:14Z",
    "date_modified": "2021-04-29T09:47:14Z",
    "currency": "aud",
    "product_id": 124
  },
  "124CHF": {
    "price_list_id": 1,
    "variant_id": 89,
    "price": 48,
    "sale_price": null,
    "retail_price": null,
    "map_price": null,
    "calculated_price": 48,
    "date_created": "2021-04-29T09:47:15Z",
    "date_modified": "2021-05-24T19:12:32Z",
    "currency": "chf",
    "product_id": 124
  }
};